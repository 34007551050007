<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("farmers.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <h6 class="ssp-24">{{ $t("farmers.title") }}</h6>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('farmers.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Nama SP" active>
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchSP"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="sp in dataSP"
                        :key="sp.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="sp.id"
                          v-model="filterSP"
                          :id="sp.id"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="sp.id"
                        >
                          {{ sp.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterSP == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Nama TSS">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchTSS"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="tss in dataTSS"
                        :key="tss.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="tss.id"
                          :id="tss.id"
                          v-model="filterTSS"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="tss.id"
                        >
                          {{ tss.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterTSS == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive style="overflow: hidden;">
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('farmers.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="this.dataPetani.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="petani in dataPetani"
                  :key="petani.id"
                >
                  <b-td>
                    <input
                      type="checkbox"
                      :value="petani.id"
                      v-model="petaniIds"
                      @click="select"
                    />
                  </b-td>
                  <b-td>
                    <div class="row pl-3 ssp-16-700">{{ petani.name }}</div>
                    <!-- <a :href="`https://wa.me/62${petani.hp.slice(1)}`">{{ `+62${petani.hp.slice(1)}` }}</a> -->
                    <div class="row pl-3">{{ `+62${petani.hp.slice(1)}` }}</div>
                  </b-td>
                  <b-td>{{ petani.sp.name }}</b-td>
                  <b-td>{{ petani.tss.name }}</b-td>
                  <b-td>{{ `${petani.luas} Ha` }}</b-td>
                  <b-td>{{ petani.ucrops.crop }}</b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="openModalDetail(petani)"
                      ></i>
                      <i
                        class="ri-whatsapp-fill ri-lg ri-mid action-btn"
                        @click="redirectWA(`+62${petani.hp.slice(1)}`)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="7">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-petani" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">
              {{ $t("farmers.label.name") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="nama-petani"
              v-model="formTambah.nama"
              placeholder="Masukkan nama petani"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">
              {{ $t("farmers.label.sp") }}
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.sp"
              disabled
            />
          </div>
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">
              {{ $t("farmers.label.tss") }}
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.tss"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">{{
              $t("farmers.label.subdistrict")
            }}</label>
            <!-- <custom-select :options="dataProv" valueProp="id" label="province" v-model="formTambah.id_prov"
              :searchable="true" trackBy="province" placeholder="Pilih Provinsi" /> -->
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.kec"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.village") }}
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.kel"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.address") }}
            </label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.alamat.$error }"
              id="alamat-petani"
              cols="30"
              rows="5"
              placeholder="Masukkan alamat disini"
              v-model="$v.formTambah.alamat.$model"
              :disabled="isDisabled"
            ></textarea>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.phone") }}
            </label>
            <input
              type="number"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.no_hp.$error }"
              id="hp-petani"
              v-model="$v.formTambah.no_hp.$model"
              placeholder="Masukkan no hp disini"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.landarea") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="ll-petani"
              v-model="$v.formTambah.luas.$model"
              :class="{ 'invalid-form': $v.formTambah.luas.$error }"
              placeholder="Masukkan luas lahan garapan disini"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.main_cult") }}
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.crop"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.other_cult") }}
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.ocrop"
              disabled
            />
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import JwtService from "@/core/services/jwt.service";

// import moment from "moment";
import { RoleType } from "@/graphql/enum.type.js";

import { Q_LIST_FARMER, Q_LIST_USERSEARCH } from "@/graphql/queries";
import { required } from "vuelidate/lib/validators";
import Axios from "axios";
// import * as XLSX from "xlsx";

export default {
  components: {
    Card
  },
  data() {
    return {
      isLoading: false,
      dataPetani: [],
      dataSP: [],
      dataTSS: [],
      optionRole: [RoleType.TSS, RoleType.SP],

      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      provID: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterSP: [],
      filterTSS: [],
      searchSP: "",
      searchTSS: "",

      spID: [],
      tssID: JwtService.getID(),

      modalTitle: null,
      isDisabled: null,
      isDetail: null,

      formTambah: {
        id: "",
        nama: "",
        id_sp: "",
        id_tss: "",
        id_kec: "",
        id_kel: "",
        alamat: "",
        no_hp: "",
        luas: "",
        id_crop: "",
        id_ocrop: ""
      },

      formDetail: {
        sp: "",
        tss: "",
        kec: "",
        kab: "",
        crop: "",
        ocrop: ""
      },

      selected: [],
      allSelected: false,
      petaniIds: []
    };
  },
  validations: {
    formTambah: {
      nama: { required },
      id_sp: { required },
      id_tss: { required },
      id_kec: { required },
      id_kel: { required },
      alamat: { required },
      no_hp: { required },
      luas: { required },
      id_crop: { required },
      id_ocrop: { required }
    }
  },
  apollo: {
    listPetani: {
      query: () => Q_LIST_FARMER,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          sp: this.filterSP,
          tss: this.filterTSS,
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          province_id: this.getProvinceID(),
          kabupaten_id: []
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataPetani = data.listPetani.petanis;
        this.totalPages = data.listPetani.totalPage;
        this.totalData = data.listPetani.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listSP: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchSP,
          role: this.optionRole[1],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataSP = data.listUser.users;
      }
    },
    listTSS: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchTSS,
          role: this.optionRole[0],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataTSS = data.listUser.users;
      }
    }
  },
  methods: {
    redirectWA(data) {
      window.open(`http://wa.me/${data}`, "_blank");
    },
    resetFilter() {
      // this.filterSelected1 = null;
      this.filterSP = [];
      this.filterTSS = [];
    },

    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    openModalDetail(data) {
      this.modalTitle = this.$t("farmers.details");
      this.isDisabled = true;
      this.isDetail = true;
      this.$bvModal.show("modal-petani");

      this.formTambah.nama = data.name;
      this.formDetail.sp = data.sp.name;
      this.formDetail.tss = data.tss.name;
      this.formDetail.kec = data.kec.kecamatan;
      this.formDetail.kel = data.kel.kelurahan;
      this.formTambah.alamat = data.alamat;
      this.formTambah.no_hp = data.hp;
      this.formTambah.luas = data.luas;
      this.formDetail.crop = data.ucrops.crop;
      this.formDetail.ocrop = data.ocrops.crop;
    },
    resetForm() {
      this.formTambah.nama = "";
      this.formTambah.id_sp = "";
      this.formTambah.id_tss = "";
      this.formTambah.id_kec = "";
      this.formTambah.id_kel = "";
      this.formTambah.alamat = "";
      this.formTambah.no_hp = "";
      this.formTambah.luas = "";
      this.formTambah.id_crop = "";
      this.formTambah.id_ocrop = "";
    },
    closeModalTambah() {
      this.$bvModal.hide("modal-petani");
      this.resetForm();
    },
    selectAll() {
      this.petaniIds = [];
      if (!this.allSelected) {
        for (let i in this.dataPetani) {
          this.petaniIds.push(this.dataPetani[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    async handleExport() {
      this.isLoading = true;
      Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/petani/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `petani-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style></style>
